import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import AppBarComponent from "../shared/AppBar";

const LanguageContext = React.createContext({
    language: "is",
    setLanguage: (language: string) => {}
});
export { LanguageContext }

const LayoutComponent = () => {

    const [language, setLanguage] = useState("is");
    const value = { language, setLanguage };

    return (
        <LanguageContext.Provider value={value}>
            <AppBarComponent />
            <Outlet />
        </LanguageContext.Provider>
    )
}

export default LayoutComponent