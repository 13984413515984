import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useRouteError } from "react-router-dom";
import HomeComponent from './components/Home/Home';
import LayoutComponent from './components/Layout/Layout';
import NoRouteComponent from './components/NoRoute/NoRoute';
import SignedComponent from './components/Signing/Signed';
import SurveyComponent from './components/Survey/Survey';
import './index.css';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

const importTheme = require('./theme/theme.json')
let theme = createTheme(importTheme);
theme = responsiveFontSizes(theme);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const ErrorBoundary = () => {
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return <></>;
}

root.render(
  // <React.StrictMode>
    <ThemeProvider theme={theme}> 
      <BrowserRouter>
          <Routes>
            <Route path="/" element={<LayoutComponent />} errorElement={<ErrorBoundary />} >
              <Route index element={<HomeComponent />} errorElement={<ErrorBoundary />}  />
              <Route path="survey" element={<SurveyComponent />} errorElement={<ErrorBoundary/>} />
              <Route path="signed" element={<SignedComponent />} errorElement={<ErrorBoundary />} />
              <Route path="*" element={<NoRouteComponent />} />
            </Route>
          </Routes>
      </BrowserRouter>
    </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
