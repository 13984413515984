import { IAuthSessionToken, IDokobitSigningResponse, IRefToken, IRefTokenDecryptResponse, ISessionIdVerifyResponse, ISubmitData, IAuthTokenDecryptResponse } from "../types/data-model";
import { getBase64Str, getChecksumSha256 } from "./utils";

export const decryptRefToken = async (token: string) : Promise<IRefTokenDecryptResponse> => {

    const res = await fetch(`api/auth/decrypt`, {
        method : 'GET',
        headers : {
            'Content-Type': 'application/json',
            'x-ref-token' : token
        }
    });

    if(res.ok) {
        const refToken : IRefToken = await res.json();
        const refSession = res.headers.get("x-session-token");
        if(refSession === null) throw new Error("Session token error");
        return {
            token : refToken,
            sessionId : refSession
        };
    }
    else {
        throw new Error("Ref token validation failed");
    }
}

export async function validateToken(sessionId: string) : Promise<ISessionIdVerifyResponse> {
    const res = await fetch(`api/auth/jwtvalidate`, {
        method : 'GET',
        headers : {
            'Content-Type': 'application/json',
            'x-session-token' : sessionId
        }
    });

    if(res.ok) {
        return await res.json();
    }
    else {
        throw new Error("Token validation failed.")
    }
}

export async function decryptToken(authtoken: string | null, referencetoken: string | null, counted: string) : Promise<IAuthTokenDecryptResponse> {
    
    if(authtoken == null){
        authtoken = '';
    }

    if(referencetoken == null){
        referencetoken = '';
    }

    const res = await fetch(`api/auth/jwtdecrypt`, {
        method : 'GET',
        headers : {
            'Content-Type': 'application/json',
            'x-session-token' : authtoken,
            'x-reference-token': referencetoken,
            'x-auth-counted':counted
        }
    });

    if(res.ok) {
        return await res.json();
    }
    else {
        throw new Error("Token decryption failed.")
    }
}

export async function createDokobitSigning(blob : Blob, amlId : string, sessionId : string) : Promise<IDokobitSigningResponse> {

    const getCheckSum = await getChecksumSha256(blob);
    const base64str = await getBase64Str(blob);
    
    const data = {
        type : "pdf",
        name : "Áreiðanleikakönnun",
        files : [
            {
                name : `aml.pdf`,
                content : base64str,
                digest: getCheckSum
            }
        ],
        redirect_uri: `${window.location.origin}/signed`,
        signers: [
            {
                external_id: amlId,
                role: "signer",
                language: "is"
            }
        ]
    }
    
    try
    {
        const res = await fetch(`api/dokobit/signing`, {
            method : 'POST',
            headers : {
                'Content-Type': 'application/json',
                'x-session-token' : sessionId
            },
            body: JSON.stringify(data)
        });
    
        if(res.ok) {
            return res.json();
        }
        else {
            return res.json();
        }
    }
    catch(err) {
        throw new Error("")
    }
}

export async function postFormData(data: ISubmitData, sessionId: string) {

    try
    {
        const res = await fetch(`api/formdata/submit`, {
            method : 'POST',
            headers : {
                'Content-Type': 'application/json',
                'x-session-token' : sessionId
            },
            body: JSON.stringify(data)
        });
    
        if(res.ok) {
            return await res.json();
        }
        else {
            return await res.json();
        }
    }
    catch(err) {
        throw new Error("posted status failed")
    }
}

export async function createDokobitAuthSession(sessionId: string) : Promise<IAuthSessionToken> {

    const res = await fetch('api/dokobit/auth/create', {
        method : 'GET',
        headers : {
            'Content-Type': 'application/json',
            'x-session-token' : sessionId
        }
    });

    if(res.ok) {
        return await res.json();
    }
    else {
        throw new Error("Error creating auth session");
    }
}

export async function getLegalEntityRoles(amlId: string, clientId: string, sessionId: string) {
    
    const res = await fetch(`api/formdata/legal/roles?amlId=${amlId}&clientId=${clientId}`, {
        method : 'GET',
        headers : {
            'Content-Type': 'application/json',
            'x-session-token' : sessionId
        }
    });

    if(res.ok) {
        const foo = await res.json();
        return await foo;
    }
    else {
        throw new Error("Get legal entity roles failed.")
    }
}