import React, { useContext } from "react";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import { LanguageContext } from "../Layout/Layout";


const AppBarComponent = () => {

    const logo = require("../assets/pei_logo.png");
    const en = require("../assets/en_icon_48.png");
    const is = require("../assets/is_icon_48.png");
    
    const context = useContext(LanguageContext);

    const onLangClickHandler = (lang : string) => {
        context.setLanguage(lang);
    }

    return (
        <AppBar color={"secondary"} className="header" position="fixed">
            <Toolbar variant="regular"> 
                <IconButton sx={{ p: 0 }}>
                    <img alt='logo' src={logo} style={{ height : '60px', width : '83px'}} />
                </IconButton>
                { context && <Box display='flex' flexGrow={1} justifyContent={'flex-end'}>
                    <IconButton onClick={() => { onLangClickHandler("is") }} sx={{ p: 0 }}>
                        <img alt='logo' src={is} style={{ height : '45px', width : '45px'}} />
                    </IconButton>
                    <IconButton onClick={() => { onLangClickHandler("en") }} sx={{ p: 0 }}>
                        <img alt='logo' src={en} style={{ height : '45px', width : '45px'}} />
                    </IconButton>
                </Box>}                        
            </Toolbar>
        </AppBar>
    )
}

export default AppBarComponent