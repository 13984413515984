
export function getBase64Str(blob : Blob) : Promise<string> {
    return new Promise((resolve, reject) => { 
      var reader = new FileReader();
      reader.onload = function () {
        if(reader) {
            const readerResult : string = reader?.result as string;
            resolve(readerResult.replace(/^data:.+;base64,/, ''));
        }
        
      };
      reader.readAsDataURL(blob);   
    });
}

export async function getChecksumSha256(blob : Blob) {
    const uint8Array = new Uint8Array(await blob.arrayBuffer());
    const hashBuffer = await crypto.subtle.digest('SHA-256', uint8Array);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((h) => h.toString(16).padStart(2, '0')).join(''); // to hex
}