import { useCallback, useContext, useEffect, useState } from 'react'
import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { Link, useSearchParams } from 'react-router-dom';
import { IAuthSessionToken, IRefTokenDecryptResponse, ISessionIdVerifyResponse } from '../../types/data-model';
import { decryptRefToken, validateToken, createDokobitAuthSession } from '../../utils/api-data';
import { SessionType } from '../../types/components';
import { IAppText } from '../../types/app';
import { LanguageContext } from '../Layout/Layout'
import { Navigate } from "react-router-dom";

declare function DokobitIdentity(options: IOptions): void;

interface IOptions {
    sessionToken : string,
    locale : string,
    primaryColor : string,
    callback : (returnToken : string) => Promise<void>
}

const HomeComponent = () => {

    const [searchParams] = useSearchParams();
    const [sessionType, setSessionType] = useState<SessionType | null>(null);
    const [appText, setAppText] = useState<IAppText | undefined>(undefined);
    const context = useContext(LanguageContext);
    const [noWsError, setNoWsError] = useState<boolean>(true);
    const [authError, setAuthError] = useState<boolean>(false);
    const [userIsAuthenticated, setUserIsAuthenticated] = useState<boolean>();

    const enableIndividualAuth = true;
    const enableLegalAuth = true;

    const createAuthSession = useCallback(async () => {
        if(window.sessionStorage.hasOwnProperty("aml_session_id")) {
            const sessionId = window.sessionStorage.getItem("aml_session_id");
            if(sessionId) {
                try {
                    const token : IAuthSessionToken = await createDokobitAuthSession(sessionId);
                    if(token.status === "ok") {
                        const options: IOptions = {
                            sessionToken : token.session_token,
                            locale : context.language,
                            primaryColor : '#ff5339',
                            callback : authCallback
                        }
                
                        const dokobitIdentity = new (DokobitIdentity as any)(options);
                        dokobitIdentity.init();
                    }
                }
                catch(err) {
                    setNoWsError(true);
                }              
            }       
        }  
    }, [context.language]);

    const sessionHandler = useCallback(async () => { 
        try {
         
            const sessionId = window.sessionStorage.getItem("aml_session_id"); 
            const authToken = window.sessionStorage.getItem("aml_token"); 

            if(sessionId && authToken) {
                const refRes : ISessionIdVerifyResponse = await validateToken(sessionId);
                if(refRes.token_verified === true) {
                    const refRaw = window.sessionStorage.getItem("aml_ref_id");
                    if(refRaw) {
                        const refToken = await decryptRefToken(refRaw);
                        setSessionType(refToken.token.type === 936540000 ? SessionType.Individual : SessionType.Legal);
                        
                        const authTokenRes : ISessionIdVerifyResponse = await validateToken(authToken);
                        if(authTokenRes.token_verified === true) {
                            setUserIsAuthenticated(true);
                        }
                        else {
                            createAuthSession();
                        }
                    }
                }
            }
            else {
                if(searchParams.has("ref")) {
                    const refTokenRaw = searchParams.get('ref');           
                    if(refTokenRaw) {
                        const refToken : IRefTokenDecryptResponse = await decryptRefToken(refTokenRaw);
                        window.sessionStorage.setItem("aml_session_id", refToken.sessionId);
                        window.sessionStorage.setItem("aml_ref_id", refTokenRaw);
                        setSessionType(refToken.token.type === 936540000 ? SessionType.Individual : SessionType.Legal);
                        createAuthSession();
                    }
                } 
            }
        }
        catch(error) {
            createAuthSession();
        }
    }, [searchParams, createAuthSession]);

    const authCallback = async (returnToken : string) => {
        
        const res = await fetch(`api/dokobit/auth/status?token=${returnToken}`, {
            method : 'GET',
            headers : {
                'Content-Type': 'application/json'
            }
        });

        if(res.ok) {
            // set jwt token, user info
            const json = await res.json();
            window.sessionStorage.setItem("aml_token", json.token);
            setUserIsAuthenticated(true);
        }
        else {
            //const err = await res.text();
            setUserIsAuthenticated(false);
            setAuthError(true);
        }
    }

    useEffect(() => {       
        sessionHandler();
    }, [sessionHandler]);

    useEffect(() => {
        const appText : IAppText = require(`../assets/${context.language}_app_text.json`);
        setAppText(appText)   
    }, [context.language])

    const MainContainer = () => {
        return (
            <Box p={5} id="main-container">
                <div>
                    <Typography color={"textPrimary"} variant="h2" component="h1" gutterBottom>
                        {appText?.MAIN_HEADING}
                    </Typography>
                    <Typography variant="h5" color={"textPrimary"}>
                        {sessionType === SessionType.Individual && appText?.MAIN_TEXT_INDIVIDUAL}
                        {sessionType === SessionType.Legal && appText?.MAIN_TEXT}
                    </Typography>
                    <Typography variant="body1" style={{ paddingTop : '5px' }} color={"textPrimary"}>
                        {appText?.MAIN_SUB_TEXT}
                    </Typography>             
                </div>
            </Box>
        )
    }

    const AuthContainer = () => {
        if(!userIsAuthenticated) {
            return (
                <div>
                    { noWsError && !authError && <div id="Dokobit-identity-container"></div> }
                    { !noWsError && <Typography variant="subtitle2" color={"textPrimary"}>{appText?.MAIN_CONNECTION_ERROR}</Typography> }
                    { authError && <Box pt={2}><Typography variant="subtitle2" color={"error"}>{appText?.MAIN_AUTH_FAILED}</Typography></Box> }
                </div>
            );
        }
        else {
            return <Navigate replace to={`/survey`} />;
        }
    }

    const IndividualContainer = () => {
        return (
            <Box pl={5}>
                <Typography style={{ paddingTop : '10px' }} variant="subtitle2" color={"textPrimary"}>{appText?.MAIN_VALID_REF}</Typography>
                { !enableIndividualAuth && <Button component={Link} 
                        to={'/survey'}
                        color="primary" 
                        style={{ marginTop : '15px'}} 
                        variant="contained"
                >{appText?.CONFIRM_BUTTON_TEXT}</Button> }
                { enableIndividualAuth && AuthContainer()}
            </Box>        
        )
    }

    return (
        <Container className="content">
            { MainContainer() }
            { sessionType === SessionType.Individual && IndividualContainer()}
        </Container>
    )
}

export default HomeComponent