import { useEffect } from "react";
import Container from '@mui/material/Container'

const NoRouteComponent = () => {

    useEffect(() => {       
        console.log("NoRouteComponent")
    }, []);

    return (
        <Container className="content">
            404
        </Container>
    )
}

export default NoRouteComponent