export enum SessionType {
    Individual,
    Legal
}

export interface IQuestionResult {
    type: string 
    val: string | Array<IQuestionResult>
    key: string | number
    title?: string | null
}