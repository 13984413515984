import { useEffect, useState, useCallback, useContext, useRef } from "react";
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles';
import { Model, surveyLocalization } from 'survey-core';
import "survey-core/survey.i18n";
import { Survey } from 'survey-react-ui';
import { SurveyPDF } from "survey-pdf";
import { Converter } from "showdown";
import 'survey-core/defaultV2.min.css';
import './survey.css'
import { decryptRefToken, postFormData, validateToken, decryptToken } from '../../utils/api-data';
import { IRefTokenDecryptResponse, ISessionIdVerifyResponse, ISubmitData } from "../../types/data-model";
import { IQuestionResult } from "../../types/components";
import { Buffer } from 'buffer';
import { LanguageContext } from "../Layout/Layout";
import { IAppText } from "../../types/app";
import Typography from "@mui/material/Typography";
import { themeJson } from "../../theme/survey_theme";
import { getBase64Str } from "../../utils/utils";

const SurveyComponent = () => {

    const sessionId = window.sessionStorage.getItem("aml_session_id");
    const ref = window.sessionStorage.getItem("aml_ref_id");
    const authToken = window.sessionStorage.getItem("aml_token");

    const [refToken, setRefToken] = useState<IRefTokenDecryptResponse | null>(null);
    const [survey, setSurvey] = useState<Model | undefined>(undefined);
    const [appText, setAppText] = useState<IAppText | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean | undefined>(false);
    const [errorAuthenticating, setErrorAuthenticating] = useState<boolean | undefined>(false);

    const context = useContext(LanguageContext);
    let surveyData = useRef<any>();
    let surveyModel = useRef<Model>();

    const savePDF = useCallback(async (result: Model, surveyJson: any) => {

        const submit = async (submitDataObj: ISubmitData, sessionId: string) => {

            const result = await postFormData(submitDataObj, sessionId);

            if (result.status !== 'ok') {
                setError(true);
            }

            window.sessionStorage.clear();
        }

        const exportToPdfOptions = {
            fontSize: 12,
            haveCommercialLicense: true
        };

        const surveyPDF = new SurveyPDF(surveyJson, exportToPdfOptions);
        surveyPDF.mode = "display";
        surveyPDF.locale = context.language;
        surveyPDF.data = result.data;
        surveyPDF.questionsOnPageMode = "singlePage";

        var data: IQuestionResult[] = [];
        for (var key in result.data) {
            const question = result.getQuestionByName(key);
            let questionResult;
            if (!question) continue;
            if (typeof (result.data[key]) === "object") {
                questionResult = {
                    type: "array",
                    val: new Array<IQuestionResult>(),
                    key: question.title,
                    title: null
                }
                for (const i in result.data[key]) {
                    const arrItem = {
                        type: "string",
                        val: result.data[key][i],
                        key: parseInt(i),
                        title: null
                    }
                    questionResult.val.push(arrItem);
                }
            }
            else {
                let val = result.data[key];
                let title = null;

                if (question.parent.isPanel) {
                    const parentTitle = (question.parent as any).title;
                    val = result.data[key];
                    if (parentTitle) {
                        title = parentTitle
                    }
                }

                questionResult = {
                    type: typeof (result.data[key]),
                    val: val,
                    key: question.title,
                    title: title
                }
            }
            data.push(questionResult);
        }

        let objJsonStr = JSON.stringify(data);
        let objJsonB64 = Buffer.from(objJsonStr).toString("base64");
        const blob: Blob = await surveyPDF.raw('blob') as unknown as Blob;
        const pdfBase64 = await getBase64Str(blob);

        if (refToken?.token.amlId && refToken?.sessionId) {

            const ref = window.sessionStorage.getItem("aml_ref_id");

            if (ref && authToken) {

                const data: ISubmitData = {
                    pdfBase64: pdfBase64,
                    ref: ref,
                    form_data: objJsonB64,
                    auth_session: authToken
                }
                submit(data, refToken?.sessionId);
            }
            else {
                // error handling
                setIsLoading(false);
                throw new Error("undefined type");
            }

        }
        // sessionId, ref
    }, [refToken, context.language, sessionId, ref, authToken]);

    useEffect(() => {

        const renderSurvey = async () => {

            if (!surveyData.current) {
                surveyData.current = require('../assets/individual_survey.json');
            }

            if (surveyModel.current) {
                surveyModel.current.locale = context.language;
            }
            else {

                const survey = new Model(surveyData.current);
                survey.applyTheme(themeJson as any);

                surveyModel.current = survey;
                surveyModel.current.locale = context.language;
                surveyModel.current.editText = appText ? appText?.SURVEY_EDIT_TEXT : "";

                surveyLocalization.locales["is"].requiredError = "Skilyrt svæði";
                surveyLocalization.locales["en"].requiredError = "Required field";

                // prefill
                let nameField = surveyModel.current.getQuestionByName("name");
                nameField.setPropertyValue('readOnly', true);
                nameField.defaultValue = refToken?.token.amlName;

                let ssnField = surveyModel.current.getQuestionByName("ssn");
                ssnField.setPropertyValue('readOnly', true);
                ssnField.defaultValue = refToken?.token.amlCode;

                let emailField = surveyModel.current.getQuestionByName("email");
                emailField.defaultValue = refToken?.token.amlEmail;

                let addressField = surveyModel.current.getQuestionByName("address");
                addressField.defaultValue = refToken?.token.amlAddress;

                let phoneField = surveyModel.current.getQuestionByName("phone");
                phoneField.defaultValue = refToken?.token.amlPhoneNumber;

                let citizenshipField = surveyModel.current.getQuestionByName("state");
                citizenshipField.defaultValue = refToken?.token.amlCitizenship;

                surveyModel.current.onComplete.add((result, options) => {
                    savePDF(result, surveyData.current)
                });
                // Instantiate Showdown
                const converter = new Converter();
                surveyModel.current.onTextMarkdown.add(function (survey, options) {
                    // Convert Markdown to HTML
                    let str = converter.makeHtml(options.text);
                    // Remove root paragraphs <p></p>
                    str = str.substring(3);
                    str = str.substring(0, str.length - 4);
                    // Set HTML markup to render
                    options.html = str;
                });
            }

            const aml_auth_count =  window.sessionStorage.getItem("aml_auth_count");

            if (aml_auth_count!=="true") {
                console.log("aml_auth_count")
                const authTokenCode = await decryptToken(authToken, ref, "false");
                window.sessionStorage.setItem("aml_auth_count", "true");
                if (authTokenCode.code === refToken?.token.amlCode) {
                    setSurvey(surveyModel.current);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    setErrorAuthenticating(true)
                }
            } else {
                const authTokenCode = await decryptToken(authToken, ref, "true");
                if (authTokenCode.code === refToken?.token.amlCode) {
                    setSurvey(surveyModel.current);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                    setErrorAuthenticating(true)
                }
            }
        }

        //console.log(JSON.stringify(authToken));
        //const authTokenjson: authTokenjson = JSON.stringify(authToken);

        if (refToken) {
            renderSurvey();
        }

    }, [refToken, savePDF, appText, context.language, sessionId]);

    useEffect(() => {

        const validate = async () => {
            if (authToken && sessionId && ref) {

                try {

                    await validateToken(authToken);

                    const sessionRes: ISessionIdVerifyResponse = await validateToken(sessionId);
                    if (sessionRes.token_verified === true) {
                        const ref = window.sessionStorage.getItem("aml_ref_id");
                        if (ref) {
                            const token = await decryptRefToken(ref);
                            if (token) setRefToken(token);
                        }
                        else {
                            window.location.replace(window.location.origin);
                        }
                    }
                }
                catch (err) {
                    // token error
                    window.location.replace(window.location.origin);
                }
            }
            else {
                window.location.replace(window.location.origin);
            }
        }

        validate();

    }, [ref, sessionId, authToken]);

    useEffect(() => {
        const appText: IAppText = require(`../assets/${context.language}_app_text.json`);
        setAppText(appText)
    }, [context.language]);

    const Item = styled('div')(({ theme }) => ({
        backgroundColor: theme.palette.primary.main,
        ...theme.typography.body1,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.secondary.main,
        width: '100%'
    }));

    const errorContainer = () => {
        return <Typography variant="h5" color={"textPrimary"}>
            {error && appText?.SURVEY_COMPLETE_ERROR}
            {errorAuthenticating && appText?.SURVEY_AUTHENTICATE_ERROR}
        </Typography>
    }

    return (
        <Container className="content">
            {!isLoading && survey && !error && <Survey model={survey} />}
            {isLoading &&
                <Box mt={5}>
                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row" useFlexGap flexWrap="wrap">
                        <Item>
                            {appText?.IS_LOADING}
                        </Item>
                        <Item>
                            <CircularProgress color="secondary" size={'5rem'} />
                        </Item>
                    </Stack>
                </Box>}
            <Box p={5} id="main-container">
                {(error || errorAuthenticating) && errorContainer()}
            </Box>
        </Container>
    )
}

export default SurveyComponent